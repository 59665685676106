import { handleResponse, SERVER_HOST } from "@api";
import { TEvent } from "@components/types";
import axios from "axios";

export const captureEvent = async (event: TEvent) => {
    let axiosMethod = axios.post;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/events/`,
        JSON.stringify(event),
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}` } })
    return handleResponse(res);
}