import React, { useEffect } from "react";
import { AppProps } from "next/app";
import NextNProgress from 'nextjs-progressbar';
import { RecoilRoot } from "recoil";
import { ConfigProvider } from 'antd';
import "@styles/global.scss";
import "@styles/ghost.scss";
import "@styles/navbar.scss";
import "@styles/home.scss";
import { VariantSelector } from "@components/variant/variantSelector";
import { useRouter } from "next/router";
import { captureEvent } from "@api/analytics";
import { TEvent } from "@components/types";
import { hasCookie, getCookie } from 'cookies-next';
import theme from '@theme/themeConfig';
import Pwa from "@core/pwa";

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    const router = useRouter()
    useEffect(() => {
        // Track page views
        const handleRouteChange = () => captureEvent({ uid: hasCookie('uid') ? getCookie('uid') : 'NA', url: router.asPath, e: "Visit", t: "PageView" } as TEvent)
        router.events.on('routeChangeComplete', handleRouteChange)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router])
    return (
        <RecoilRoot>
            <VariantSelector />
            <NextNProgress color="#346BD9" options={{ showSpinner: false }} />
            <ConfigProvider theme={theme}>
                <Component {...pageProps} />
            </ConfigProvider>
            <Pwa />
        </RecoilRoot>
    );
}

export default MyApp;
