import React from "react";
import { Swiper } from "swiper/react";

// import Swiper styles
import "swiper/css";


interface Props {
    children: any
    direction?: 'horizontal' | 'vertical';
    extraClasses?: string
    loop?: boolean
}


export const CarouselContainer: React.FC<Props> = ({ children, extraClasses, direction, loop }) => {
    return <>
        <Swiper
            direction={direction || 'horizontal'}
            spaceBetween={20}
            slidesPerView={"auto"}
            loop={loop || false}
            // navigation={true}
            className={`mySwiper w-full overflow-x-scroll ${extraClasses || ""}`}
        >
            {children}
        </Swiper></>
};
